@import "common";


.container {
  @include centered-column;
  padding-top: 40px;
  min-width: 340px;

  .back-button {
    @include gutter;
    text-align: left;
  }

  .tab-content {
    @include gutter;
  }
}


