@import "common";


.app {

  min-width: 340px;

  .home {

  }

  .privacy {
    @include centered-column;
    div {
      @include gutter;
    }
  }

  .header {
    @include centered-column;

    background: rgb(135, 224, 254);
    background: linear-gradient(to bottom right, $red 0%, $orange 100%);
    min-height: 480px;
    color: $white;

    .menubar {
      @include gutter;
      display: flex;
      padding-top: 20px;

      justify-content: space-between;
      align-items: flex-start;

      .title {
        padding: 10px 0 4px 0;
        font-size: 1.2em;
        font-weight: 600;
      }

      .menu {
        //@include hidden-medium;
        //display: flex;
        display: none;
        font-size: 1em;
        font-weight: 400;
        flex-basis: 50%;
        justify-content: space-between;

        span {
          padding: 10px 0 4px 8px;
        }

      }

      .action {
          @include show-medium;
          display: flex;
          flex-direction: column;
          gap: 4px;

          img {
            height: 40px;
          }
      }


    }

    .hero {
      @include gutter;
      padding-top: 40px;
      flex: 1;

      .title {
        font-size: 1.8em;
        font-weight: 400;
      }

      .description {
        font-size: 1em;
        font-weight: 400;
        max-width: 60%;
      }

      .action {
        @include hidden-medium;
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px;
        gap: 10px;

        img {
          height: 40px;
        }
      }

      .screenshot {
        @include hidden-medium;
        img {
          height: 480px;
          position: absolute;
          right: 60px;
          top: 80px;
        }
      }
    }

    .hero-footer-container {
      @include centered-column;
      background-color: #f2f7f8;
      color: #7c8595;
      height: 36px;
      padding: 10px 0 10px 0;
      width: 100%;

      .hero-footer {
        @include gutter;
        img {
          height: 32px;
          opacity: 0.4;
        }
      }

    }
  }

  .screenshot-container {
    @include centered-column;
    @include show-medium;
    padding-top: 40px;
    padding-bottom: 40px;

    img {
      height: 640px;
    }

  }

  .features-container {
    @include centered-column;
    background-color: white;
    color: black;

    padding-top: 40px;
    padding-bottom: 40px;

    .title {
      font-size: 1.4em;
      font-weight: 600;
    }

    .features {
      @include gutter;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 40px;


      .column {
        flex-basis: 40%;
        display: flex;
        flex-direction: column;

        .feature {
          padding-bottom: 40px;

          display: flex;
          svg {
            padding-top: 8px;
          }
          section {
            padding-left: 16px;
            .title {
              font-size: 1.2em;
              font-weight: 400;
            }
            .description {
              font-size: 0.8em;
            }
          }

        }

        .screenshot {
          @include hidden-medium;
          img {
            height: 480px;
          }
        }
      }
    }
  }

  .footer-container {
    @include centered-column;
    background-color: $darkish-grey;
    padding-top: 10px;
    width: 100%;
    height: 160px;
    flex: 1;

    .footer {
      @include gutter;

      font-size: 0.8em;
      color: $white;
      .column {
        display: flex;
        flex-direction: column;

        a {
          text-decoration: none;
          color: $white;
          padding: 4px 0 4px 0;
        }

      }
    }
    .footer-note {
      @include gutter;
      font-size: 0.7em;
      color: $white;
      height: 80px;
      align-items: flex-end;
      display: flex;
    }
  }
}

.coming-soon {
  background-color: $orange;
  color: $dark-grey;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
  line-height: 24px;

  a {
    text-decoration: none;
    color: $dark-grey;
    padding: 4px 0 4px 0;
  }
}
