@use "sass:color";

@mixin centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin spaced-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin available-width {
  width: available;
  width: -moz-available;
  width: -webkit-fill-available;
}

@mixin available-height {
  height: available;
  height: -moz-available;
  height: -webkit-fill-available;
}

@mixin below-statusbar {
  margin-top: 45px !important;
}

@mixin below-menubar {
  margin-top: 85px !important;
}

@mixin above-keys {
  margin-bottom: 90vw !important;
}

//@mixin gutter {
//  padding-left: 20px;
//  padding-right: 20px;
//  @media screen and (min-width: 640px) and (max-width: 900px) {
//    padding-left: 80px;
//    padding-right: 80px;
//  }
//  @media screen and (min-width: 900px) and (max-width: 1100px){
//    padding-left: 240px;
//    padding-right: 240px;
//  }
//  @media screen and (min-width: 1100px) {
//    padding-left: 320px;
//    padding-right: 320px;
//  }
//}

@mixin gutter {
  width: 320px;
  @media screen and (min-width: 700px) and (max-width: 900px) {
    width: 480px;
  }
  @media screen and (min-width: 900px) and (max-width: 1100px){
    width: 640px;
  }
  @media screen and (min-width: 1100px) {
    width: 960px;
  }
}

@mixin hidden-small {
  @media screen and (max-width: 480px) {
    display: none;
  }
}

@mixin hidden-medium {
  @media screen and (max-width: 700px) {
    display: none;
  }
}

@mixin show-medium {
  @media screen and (min-width: 700px) {
    display: none;
  }
}



@font-face {
  font-family: 'ReadexPro';
  src: url('../src/Lato/Lato-Regular.ttf'); /* IE9 Compat Modes */
}

* {
  font-family: ReadexPro, sans-serif;
}

button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

$black: #070d13;
$white: #eae4d2;
$whiter: #f1ebe4;

//$black: #161e25;
//$white: #e5eff5;

$darkest-grey: mix($black, $white, 90%);
$darker-grey: mix($black, $white, 95%);
$dark-grey: mix($black, $white, 80%);
$darkish-grey: mix($black, $white, 70%);
$middle-grey: mix($black, $white, 60%);
$even-grey: mix($black, $white, 50%);
$faint-grey: mix($black, $white, 40%);
$light-grey: mix($black, $white, 30%);

$red: #d23434;
$orange: #ffb100;